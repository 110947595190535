<template lang="pug">
.static-footer
  force-update-guard

  .container.framed
    .static-footer__layout
      .static-footer__about
        nuxt-link(:to="{ name: 'index' }" alt="Pod Foods")
          img(src="/img/home/logo.footer.webp" width="168" height="36" alt="Pod Foods")

        .static-footer__apps(v-if="isLPDomain || isBuyer || isHeadBuyer || !logged")
          template(v-if="isLPDomain")
            a(:href="LP_APPSTORE_URL" target="_blank")
              img(src="/img/home/download.appstore.webp" width="140" height="40" alt="Download on the App Store")
            a(:href="LP_PLAYSTORE_URL" target="_blank")
              img(src="/img/home/download.playstore.webp" width="140" height="40" alt="Get it on Google Play")

          template(v-else-if="isBuyer || isHeadBuyer || !logged")
            a(:href="BUYER_APPSTORE_URL" target="_blank")
              img(src="/img/home/download.appstore.webp" width="140" height="40" alt="Download on the App Store")
            a(:href="BUYER_PLAYSTORE_URL" target="_blank")
              img(src="/img/home/download.playstore.webp" width="140" height="40" alt="Get it on Google Play")

        .static-footer__copyrights(v-if="copyrights" v-text="copyrights")
        .static-footer__version
          input.app-info__branch(type="hidden" :value="branch")
          input.app-info__version(type="hidden" :value="version")

      .static-footer__menu
        h3 Company
        ul
          li
            nuxt-link(:to="{ name: 'about' }" title="About Pod Foods") About Pod Foods
          li
            nuxt-link(:to="{ name: 'press' }" title="Press") Press
          li
            nuxt-link(:to="{ name: 'team' }" title="Meet the Team") Meet the Team
          li(v-if="!hideCareersPage")
            nuxt-link(:to="{ name: 'careers'}" title="Careers") Careers
          li
            nuxt-link(:to="{ name: 'terms-of-service'}" title="Terms of Service") Terms of Service
          li
            nuxt-link(:to="{ name: 'privacy'}" title="Privacy Policy") Privacy Policy
          li
            nuxt-link(:to="{ name: 'terms-of-use'}" title="Terms of Use") Terms of Use

      .static-footer__menu
        h3 Support
        ul
          li
            nuxt-link(:to="{ name: 'help-center' }" title="Help Center") Help Center
          //- li
            nuxt-link(:to="{ name: 'faq' }" title="FAQ") FAQ
          //- li
            nuxt-link(:to="{ name: 'logistics' }" title="Logistics Partners") Logistics Partners
          li
            nuxt-link(:to="{ name: 'pricing'}" title="Pricing") Pricing
          li(v-if="logged && !isBroker")
            el-popover(placement="top")
              div
                span We moved <strong>Log out</strong> to&nbsp;
                  nuxt-link.static-footer__settings(:to="toLogOut" title="Settings") Settings
                  | .
              a.static-footer__logout(href="#" @click.prevent slot="reference") Logout
                i.ml-1.bx.bx-lock

      .static-footer__menu
        h3 Connect
        ul
          li
            nuxt-link(:to="{ name: 'blog' }" title="Blog") Blog
          li
            nuxt-link(:to="{ name: 'podcasts' }" title="Podcasts") Podcasts
          li
            a(:href="FACEBOOK_URL" target="_blank" title="Contact us via Facebook") Facebook
          li
            a(:href="INSTAGRAM_URL" target="_blank" title="Contact us via Instagram") Instagram
          li
            a(:href="LINKEDIN_URL" target="_blank" title="Contact us via LinkedIn") LinkedIn
          li
            a(:href="`mailto:${CONTACT_EMAIL}`" target="_blank" title="Email") Email

</template>

<script>
import { mapGetters } from 'vuex'
import { CONTACT_EMAIL, FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL, BUYER_APPSTORE_URL, BUYER_PLAYSTORE_URL, LP_APPSTORE_URL, LP_PLAYSTORE_URL } from '~/settings'
import ForceUpdateGuard from '~/components/ForceUpdateGuard'

export default {
  components: {
    ForceUpdateGuard
  },

  data () {
    return {
      CONTACT_EMAIL,
      FACEBOOK_URL,
      INSTAGRAM_URL,
      LINKEDIN_URL,
      BUYER_APPSTORE_URL,
      BUYER_PLAYSTORE_URL,
      LP_APPSTORE_URL,
      LP_PLAYSTORE_URL,
      copyrights: null
    }
  },

  fetch () {
    this.copyrights = `Copyright © 2017-${(new Date()).getFullYear()} Pod Foods Co`
  },

  computed: {
    ...mapGetters({
      logged: 'authentication/logged',
      isBroker: 'authentication/isBroker',
      isLPDomain: 'authentication/isLPDomain',
      isBuyer: 'authentication/isBuyer',
      isHeadBuyer: 'authentication/isHeadBuyer',
      toSettings: 'authentication/toSettings',
      hideCareersPage: 'configs/hideCareersPage'
    }),

    branch: () => process.env.CURRENT_APP_BRANCH,
    version: () => process.env.CURRENT_APP_VERSION,

    toLogOut () {
      return {
        ...this.toSettings,
        hash: '#logout'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.static-footer {
  background: $--color-alternative;
  position: relative;
  z-index: 1;
  padding: 48px 0 64px;
  @include media(md) {
    padding: 96px 0;
  }
  &__layout {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    flex-wrap: wrap;
    > * {
      min-width: 0;
    }
  }
  &__about {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    @include media(md) {
      gap: 3rem;
    }
  }
  &__apps {
    display: flex;
    gap: 16px;
    a {
      img {
        height: auto;
      }
    }
  }
  &__copyrights {
    font-size: 12px;
    color: rgba(white, .5);
  }
  &__version {
    display: none;
  }
  &__menu {
    h3 {
      color: white;
      font-size: 1rem;
      font-weight: $--font-weight-bold;
      margin: 0;
      + ul {
        margin-top: 24px;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      line-height: 150%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      > * {
        min-height: 0;
      }
      a {
        display: block;
        color: rgba(white, .5);
        &:hover {
          color: white;
        }
      }
    }
  }
  &__logout {
    color: $--color-warning !important;
  }
  &__settings {
    color: $--color-primary;
    font-weight: bold;
  }
}
</style>
