<template lang="pug">
client-only
  .force-update-guard
</template>

<script>
import { DEPLOYMENT_INFO_FILE } from '~/settings'

const INTERVAL = 60 * 1000

export default {
  data () {
    return {
      timeout: null,
      branch: process.env.CURRENT_APP_BRANCH
    }
  },

  mounted () {
    if (this.$nuxt.context.isDev) return
    this.timeout = setTimeout(this.check, INTERVAL)
  },

  methods: {
    async check () {
      await this.$axios
        .$get(DEPLOYMENT_INFO_FILE, {
          progress: false,
          baseURL: '/',
          params: {
            t: Date.now()
          }
        })
        .then((res = {}) => {
          const { CURRENT_APP_BRANCH } = res
          if (CURRENT_APP_BRANCH && this.branch !== CURRENT_APP_BRANCH) {
            this.$amplitude.track('SHOW_FORCE_UPDATE_WARNING', {
              current_branch: this.branch,
              target_branch: CURRENT_APP_BRANCH
            })
            this
              .$alert(
                'Reload now to keep your version run smoothly and correctly.',
                'You are using an older version!', {
                type: 'warning',
                showClose: false,
                showCancelButton: true,
                closeOnClickModal: false,
                closeOnHashChange: false,
                cancelButtonText: 'Ignore',
                confirmButtonText: 'Reload'
              })
              .then(() => {
                window.location.reload()
              })
              .catch(() => {
                this.branch = CURRENT_APP_BRANCH
              })
          } else {
            this.timeout = setTimeout(this.check, INTERVAL)
          }
        })
        .catch(() => {})
    }
  }
}
</script>
